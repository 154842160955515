var site = site || {};


$(document).ready(function() {
 var mobileSmsOptions = $('#sms_promotion_container div.sms_options_container')[0];
    var formNode = $('form')[0];
	site.mobileSmsOptions.init({
         //checkboxNode: $('#form--checkout_shipping--field--GIFTWRAP'),
         checkboxNode: $('#sms_promotion_container input[name="SMSPROMO_SMS_PROMOTIONS"]')[0],
         containerNode: mobileSmsOptions,
         formNode: formNode,
         //formNode: $('#checkout_registration'),
         defaultMobileNode: $('#sms_promotion_container input[name="SMSPROMO_DEFAULT_MOBILE_NUMBER"]')[0],
         defaultCarrierNode: $('#sms_promotion_container input[name="SMSPROMO_DEFAULT_MOBILE_PHONE_CARRIER"]')[0],
         messageTextNode: $('#sms_promotion_container input.sms_input')[0]
    });

	$('.gnav_sms_promo').on('click', function(event) {
		$('#gnav_mobile_number').toggleClass('hidden');
	})
});


site.smsOptions = {
    checkboxNode: null,
    containerNode: null,
    formNode: null,
    defaultMobileNode: null,
    defaultMobileValue: null,
    defaultCarrierNode: null,
    defaultCarrierValue: null,
    
    init: function(args) {
        if (!args) return;
        $.extend(this, args);
        if (!this.checkboxNode || !this.containerNode) return;

        // get initial values for mobile phone and carrier in case we have to reset them
        if (this.defaultMobileNode) {
            this.defaultMobileValue = this.defaultMobileNode.value;
        }
        if (this.defaultCarrierNode) {
            this.defaultCarrierValue = this.defaultCarrierNode.value;
        }

        var self = this;

        $(this.containerNode).addClass('hidden');
        $(this.checkboxNode).on('click', function(e) {
            if (self.checkboxNode.checked) {
                self.showOptions();
            } else {
                self.hideOptions();
                self.clearOptions(self.defaultMobileValue,self.defaultCarrierValue);
            }
        });

		 if (self.checkboxNode.checked) {
                self.showOptions();
            } else {
                self.hideOptions();
                self.clearOptions(self.defaultMobileValue,self.defaultCarrierValue);
            }

        

        if (args.callback) {
            args.callback();
        }
    },

    showOptions: function() {
        $(this.containerNode).removeClass('hidden');
    },
    hideOptions: function() {
        $(this.containerNode).addClass('hidden');
    },
    clearOptions: function(clearMobileNum,clearCarrier) {
        var num = $('#sms_promotion_container input[name="SMSPROMO_MOBILE_NUMBER"]');
        var carr = $('#sms_promotion_container input[name="SMSPROMO_MOBILE_PHONE_CARRIER"]');
        if (num.length > 0) {
            num.val(clearMobileNum);
        }
        if (carr.length > 0) {
            carr.val(clearCarrier);
        }
        
    }
};
site.mobileSmsOptions = site.smsOptions;
